import TodoList from "./components/TodoList";
import Rtl from "./common/Rtl";
import { TodosContext } from "./context/todosContext";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";

const initialTodos = [
  {
    id: uuidv4(),
    title: "كتاب الاول",
    details: "تيست اول",
    isCompleted: false,
  },
  {
    id: uuidv4(),
    title: "كتاب الاول",
    details: "تيست اول",
    isCompleted: false,
  },
  {
    id: uuidv4(),
    title: "كتاب الاول",
    details: "تيست اول",
    isCompleted: false,
  },
  {
    id: uuidv4(),
    title: "كتاب الاول",
    details: "تيست اول",
    isCompleted: false,
  },
  {
    id: uuidv4(),
    title: "كتاب الاول",
    details: "تيست اخير",
    isCompleted: false,
  },
];

function App() {
  const [todos, setTodos] = useState(initialTodos);
  return (
    <Rtl>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100dvh",
          backgroundColor: "#222",
        }}
      >
        <TodosContext.Provider value={{ todos, setTodos }}>
          <TodoList />
        </TodosContext.Provider>
      </div>
    </Rtl>
  );
}

export default App;
