import * as React from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Todo from "./Todo";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";

import { useContext } from "react";
import { TodosContext } from "../context/todosContext";
import { v4 as uuidv4 } from "uuid";

export default function TodoList() {
  const { todos, setTodos } = useContext(TodosContext);
  const [input, setInput] = React.useState("");
  const [displyedItems, setDisplyedItems] = React.useState("all");

  React.useEffect(() => {
    setTodos(JSON.parse(localStorage.getItem("todos")) || []);
  }, [setTodos]);

  let pageItems = todos;
  if (displyedItems === "non-comp") {
    pageItems = todos.filter((t) => !t.isCompleted);
  } else if (displyedItems === "comp") {
    pageItems = todos.filter((t) => t.isCompleted);
  }

  const TodoJsx = pageItems.map((t) => {
    return <Todo key={t.id} todo={t} />;
  });
  function handelInputChange(e) {
    setInput(e.target.value);
  }

  function handelAddClick() {
    const newtodosA = [
      ...todos,
      {
        id: uuidv4(),
        title: input,
        details: "تيست اخير",
        isCompleted: false,
      },
    ];
    setTodos(newtodosA);
    localStorage.setItem("todos", JSON.stringify(newtodosA));
    setInput("");
  }

  return (
    <Container maxWidth="sm">
      <Card sx={{ minWidth: 275, paddingY: "20px" }}>
        <CardContent
          sx={{ paddingY: "0px", maxHeight: "70dvh", overflowY: "scroll" }}
        >
          <Typography variant="h3">Word of the Day</Typography>
          <Divider />
          <ToggleButtonGroup
            onChange={(e) => {
              setDisplyedItems(e.target.value);
            }}
            value={displyedItems}
            exclusive
            sx={{
              marginY: "20px",
              display: "flex",
              justifyContent: "center",
            }}
            aria-label="text alignment"
          >
            <ToggleButton value="all">الكل</ToggleButton>
            <ToggleButton value="comp">المنجزة</ToggleButton>
            <ToggleButton value="non-comp">الغير منجزة</ToggleButton>
          </ToggleButtonGroup>

          {/* todo */}
          {TodoJsx}
          {/* todo */}
        </CardContent>
        <CardActions sx={{ margin: "9px" }}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                value={input}
                onChange={handelInputChange}
                id="outlined-textarea"
                label="Multiline Placeholder"
                placeholder="Placeholder"
                multiline
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={input.length === 0}
                onClick={handelAddClick}
                variant="contained"
                sx={{ height: "100%", width: "100%" }}
                size="larg"
              >
                اضافة تاسك
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Container>
  );
}
