import * as React from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { MdOutlineDeleteSweep } from "react-icons/md";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { FiEdit3 } from "react-icons/fi";
import { MdDoNotDisturbAlt } from "react-icons/md";

import { TodosContext } from "../context/todosContext";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

export default function Todo({ todo }) {
  const { todos, setTodos } = React.useContext(TodosContext);
  const [open, setOpen] = React.useState(false);
  const [updateOpen, setUpdateOpen] = React.useState(false);
  const [updateForm, setupdateForm] = React.useState({
    title: todo.title,
    details: todo.details,
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"هل انت متاكد من انك تريد حذف التاسك؟"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            سيتم حذف تاسك بعنوان
            <br />
            <strong>{todo.title}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            رفض الحذف
          </Button>
          <Button
            onClick={() => {
              const newTodosD = todos.filter((todoItem) => {
                return todoItem.id !== todo.id;
              });
              localStorage.setItem("todos", JSON.stringify(newTodosD));
              setTodos(newTodosD);
            }}
          >
            موافقة الحذف
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={updateOpen}
        onClose={() => {
          setUpdateOpen(false);
        }}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const newTodosE = todos.map((t) => {
              if (t.id === todo.id) {
                return {
                  ...t,
                  title: updateForm.title,
                  details: updateForm.details,
                };
              }
              return t;
            });
            setTodos(newTodosE);
            localStorage.setItem("todos", JSON.stringify(newTodosE));
            setUpdateOpen(false);
          },
        }}
      >
        <DialogTitle>تعديل التساك</DialogTitle>
        <DialogContent>
          <DialogContentText>
            انت الان تقوم بتعديل التاسك احذر
          </DialogContentText>
          <TextField
            value={updateForm.title}
            onChange={(e) => {
              setupdateForm({
                ...updateForm,
                title: e.target.value,
              });
            }}
            autoFocus
            margin="dense"
            id="title"
            name="title"
            label="العنوان الرئيسي"
            fullWidth
            variant="standard"
          />
          <TextField
            value={updateForm.details}
            onChange={(e) => {
              setupdateForm({
                ...updateForm,
                details: e.target.value,
              });
            }}
            margin="dense"
            id="details"
            name="details"
            label="الوصف"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setUpdateOpen(false);
            }}
          >
            إلغاء الامر
          </Button>
          <Button type="submit">تحديث</Button>
        </DialogActions>
      </Dialog>

      <Card
        sx={{
          backgroundColor: "#003b5e",
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
          marginY: "10px",
        }}
      >
        <CardContent sx={{ padding: 0 }}>
          <Typography
            variant="h4"
            sx={{
              fontSize: 20,
              color: "#fff",
              textDecoration: todo.isCompleted ? "line-through" : "",
            }}
            gutterBottom
          >
            {todo.title}
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            sx={{ fontSize: 14, color: "#fff" }}
          >
            {todo.details}
          </Typography>
        </CardContent>
        <CardActions
          sx={{ padding: 0, color: "#fff", fontSize: "19px", gap: "7px" }}
        >
          {!todo.isCompleted ? (
            <IoCheckmarkDoneSharp
              onClick={() => {
                const newTodosL = todos.map((t) => {
                  if (t.id === todo.id) {
                    todo.isCompleted = !todo.isCompleted;
                  }
                  return t;
                });
                localStorage.setItem("todos", JSON.stringify(newTodosL));
                setTodos(newTodosL);
              }}
              className="card-icon"
              style={{
                backgroundColor: "#0e7010",
                padding: "8px",
                border: "1px solid #a1a1a1",
                borderRadius: "50px",
                margin: "0px",
                boxShadow: "2px 3px 3px 0px #dddddd52",
              }}
            />
          ) : (
            <MdDoNotDisturbAlt
              onClick={() => {
                const newTodosL = todos.map((t) => {
                  if (t.id === todo.id) {
                    todo.isCompleted = !todo.isCompleted;
                  }
                  return t;
                });
                localStorage.setItem("todos", JSON.stringify(newTodosL));
                setTodos(newTodosL);
              }}
              className="card-icon"
              style={{
                backgroundColor: "#bb5d5d",
                padding: "8px",
                border: "1px solid #a1a1a1",
                borderRadius: "50px",
                margin: "0px",
                boxShadow: "2px 3px 3px 0px #dddddd52",
              }}
            />
          )}

          <FiEdit3
            onClick={() => {
              setUpdateOpen(true);
            }}
            className="card-icon"
            style={{
              backgroundColor: "#0f6b6e",
              padding: "8px",
              border: "1px solid #a1a1a1",
              borderRadius: "50px",
              margin: "0px",
              boxShadow: "2px 3px 3px 0px #dddddd52",
            }}
          />
          <MdOutlineDeleteSweep
            onClick={() => {
              setOpen(true);
            }}
            className="card-icon"
            style={{
              backgroundColor: "#7e1b1b",
              padding: "8px",
              border: "1px solid #a1a1a1",
              borderRadius: "50px",
              margin: "0px",
              boxShadow: "2px 3px 2px 0px #dddddd52",
            }}
          />
        </CardActions>
      </Card>
    </>
  );
}
